import './css/-375-Width__Medium.css';
import './css/-375-Width__Short.css';
import './css/-375-Width__Tall.css';
import './css/375_500-Width__Medium.css';
import './css/375_500-Width__Short.css';
import './css/375_500-Width__Tall.css';
import './css/500_750-Width__Medium.css';
import './css/500_750-Width__Short.css';
import './css/500_750-Width__Tall.css';
import './css/750_1000-Width__Medium.css';
import './css/750_1000-Width__Short.css';
import './css/750_1000-Width__Tall.css';
import './css/1000_1350-Width__Medium.css';
import './css/1000_1350-Width__Short.css';
import './css/1000_1350-Width__Tall.css';
import './css/1350_1600-Width__Medium.css';
import './css/1350_1600-Width__Short.css';
import './css/1350_1600-Width__Tall.css';
import './css/1600+Width__Medium.css';
import './css/1600+Width__Short.css';
import './css/1600+Width__Tall.css';
import './css/base.css';


import React, { useState, useEffect, Component, useRef } from 'react';
import axios from 'axios';

function Home() {
    const [formData, setFormData] = useState({
        orderID: '',
        orderSecretKey: ''
      });

      const [redeemLoading,setRedeemLoading] = useState(false);
      const [redemptionStatus,setRedemptionStatus] = useState(0);
      const [redemptionError,setRedemptionError] = useState(false);
      const [redemptionErrorMessage,setRedemptionErrorMessage] = useState();

      const [redeemDetails,setRedeemDetails] = useState();
      const [downloadProgress,setDownloadProgress] = useState(0);
      const [downloadStatus,setDownloadStatus] = useState(0);
      const [downloadErrorMessage,setDownloadErrorMessage] = useState();
      //downloadStatus 
        // 0 = Not Started
        // 1 = Download Triggered
        // 2 = Download Failed
        //3 = Downlaod Success
      const [isFormComplete, setIsFormComplete] = useState(false);

        // This effect checks if the form is complete whenever formData is updated
        useEffect(() => {
            console.log(formData);
            const { orderID, orderSecretKey } = formData;
            // The form is complete when both fields are not empty
            if (orderID && orderSecretKey) {
            setIsFormComplete(true);
            } else {
            setIsFormComplete(false);
            }
        }, [formData]);

        // Handle form input changes
        const handleInputChange = (e) => {
            const { name, value } = e.target;
            setFormData({
            ...formData,
            [name]: value
            });
        };

        useEffect(()=>{
            if(redemptionStatus === 1){
                //Reset full redemption attempt on all
                setTimeout(()=>{
                    setFormData(prevState => ({
                        ...prevState,
                        orderID: '',
                        orderSecretKey: ''
                    }));
                    setIsFormComplete(false);
                    setRedemptionError(false);
                    setRedemptionErrorMessage();
                    setRedemptionStatus(0);
                },4000);
            }
            if(redemptionStatus === 2){

            }
        },[redemptionStatus])

        useEffect(()=>{
            if(downloadStatus === 2){
                //Reset full redemption attempt on all
                setTimeout(()=>{
                    setFormData(prevState => ({
                        ...prevState,
                        orderID: '',
                        orderSecretKey: ''
                    }));
                    setIsFormComplete(false);
                    setRedemptionError(false);
                    setRedemptionErrorMessage();
                    setRedemptionStatus(0);
                    setRedeemDetails();
                    setDownloadProgress(0);
                    setDownloadStatus(0);
                    setDownloadErrorMessage();
                },4000);
            }

        },[downloadStatus])

        const handleSubmit = (e) => {
            e.preventDefault();

            if (isFormComplete) {
                setRedeemLoading(true);

                // Introduce a delay of at least 0.8 seconds
                const startTime = Date.now();

            axios.post('https://visiontec.store/redeem', formData)
                .then(response => {
                    const elapsedTime = Date.now() - startTime;
                    const remainingTime = Math.max(800 - elapsedTime, 0);

                    setTimeout(() => {
                        console.log('Response:', response.data);
                        console.log('Response Status:', response.status);
                        setRedemptionStatus(2);
                        setRedeemLoading(false);
                        setRedeemDetails(response.data);
                    }, remainingTime);
                })
                .catch(error => {
                    const elapsedTime = Date.now() - startTime;
                    const remainingTime = Math.max(800 - elapsedTime, 0);
                    if (error.response) {
                        // The request was made and the server responded with a status code that falls out of the range of 2xx
                        console.error('Request failed with status:', error.response.status);
                        console.error('Error message:', error.response.data);
                        //502
                        //404 - Order Not found
                        //406 - Password mismatch
                        let rMessage;
                        if(error.response.status === 502){
                            rMessage = 'Server Error. This has been reported. Try back soon';
                        }
                        if(error.response.status === 404){
                            rMessage = 'Order not found';
                        }
                        if(error.response.status === 406){
                            rMessage = 'ID and Key mismatch';
                        }


                        setTimeout(() => {
                            setRedemptionStatus(1);
                            setRedemptionError(true);
                            setRedemptionErrorMessage(rMessage);
                            setRedeemLoading(false);
                        }, remainingTime);

                    } else if (error.request) {
                        // The request was made but no response was received
                        console.log('No response received:', error.request);
                        setTimeout(() => {
                            setRedemptionStatus(1);
                            setRedemptionError(true);
                            setRedemptionErrorMessage('Network error. Please check connection');
                            setRedeemLoading(false);
                        }, remainingTime);

                    } else {
                        // Something happened in setting up the request that triggered an Error
                        console.error('Error:', error.message);
                        
                        setTimeout(() => {
                            setRedemptionStatus(1);
                            setRedemptionError(true);
                            setRedemptionErrorMessage('Something went wrong. Please try again');
                            setRedeemLoading(false);
                        }, remainingTime);

                    }
                });
            } else {
                //Incomplete form
                console.log('Form is incomplete!');
            }
        };



        function extractFilenameFromResponse(response) {
            const contentDisposition = response.headers['content-disposition'];
            if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
                const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                const matches = filenameRegex.exec(contentDisposition);
                if (matches != null && matches[1]) return matches[1].replace(/['"]/g, '');
            }
            return null;
        }

        const clickToRedeem = async () => {
            if(redeemDetails && downloadStatus === 0){
                setDownloadStatus(1);


                axios.post('https://visiontec.store/download', redeemDetails, {
                    responseType:'blob',
                    headers: {
                        Authorization:`${redeemDetails.token}`
                    },
                    onDownloadProgress: (progressEvent) => {
                        const totalLength = progressEvent.lengthComputable
                          ? progressEvent.total
                          : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
                        
                        if (totalLength !== null) {
                          const progress = Math.round((progressEvent.loaded * 100) / totalLength);
                          setDownloadProgress(progress);
                          console.log(`Download Progress: ${progress}%`); // Monitor download progress if server responds with a file
                        }
                      }
                })
                    .then(response => {
                        const filename = extractFilenameFromResponse(response);

                        // Check if the response is a Blob (file data) to trigger the download
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', filename || 'VisionTec.zip'); // Use extracted filename or default
                        document.body.appendChild(link);
                        link.click();
                        link.remove(); // Clean up the element after download

                        // Trigger an event or log after the download completes
                        console.log('Data sent and download complete!');
                        setDownloadStatus(3);
                        //Trigger Completion Axios
                    })
                .catch(error => {

                    if (error.response) {
                        // The request was made and the server responded with a status code that falls out of the range of 2xx
                        console.error('Request failed with status:', error.response.status);
                        console.error('Error message:', error.response.data);
                        //502
                        //404 - Order Not found
                        //406 - Password mismatch
                        let rMessage;
                        if(error.response.status === 408){
                            //Expired token. Restart process
                            rMessage = 'Redemption Timed Out. Please try again';
                        }
                        if(error.response.status === 409){
                            //Invalid Token. Server Error. Start Over
                            rMessage = 'Server Error. Please Try Again';
                        }
                        if(error.response.status === 404){
                            //Order not found, may have been previous order
                            rMessage = 'This order not found. If this order has not already been redeemed please contact support. Thank you!';
                        }
                        if(error.response.status === 500){
                            //Internal Server Error - Try Again
                            rMessage = 'Something went wrong. Please try again soon';
                        }
                        if(error.response.status === 406){
                            //Key Mismatch, Start Again
                            rMessage = 'Something went wrong. Please try again soon';
                        }
                        if(error.response.status === 405){
                            //File not found
                            rMessage = 'Having trouble locating this file. Please try again soon. If problem persists, please contact support';
                        }
                        if(error.response.status === 502){
                            //Server / Network Error
                            rMessage = 'Something went wrong. Please try again soon';
                        }
                        setDownloadStatus(2);
                        setDownloadErrorMessage(rMessage);

                    } else if (error.request) {
                        // The request was made but no response was received
                        console.log('No response received:', error.request);
                        let rMessage = 'Something went wrong. Please try again soon'
                        setDownloadStatus(2);
                        setDownloadErrorMessage(rMessage);

                    } else {
                        // Something happened in setting up the request that triggered an Error
                        console.error('Error:', error.message);
                        let rMessage = 'Something went wrong. Please try again soon'
                        setDownloadStatus(2);
                        setDownloadErrorMessage(rMessage);
                    }
                });
            }else{
                //Error that should never happen
                //App broken try again
            }
        }


    return(
        <div className="home-wrap" style={{backgroundImage:`url(./img/cloud_wave_hyper.png)`}}>
            <div className="main-console">
                <div className="store-header">
                    <div className="image-wrap">
                        <img src="/img/store_image.png" />
                    </div>
                    <div className="text-wrap">
                        <div className="title Gelasio">
                            VisionTec
                        </div>
                        <div className="slogan Urbanist">
                            Art Beyond Boundaries
                        </div>
                    </div>
                </div>
                <div className="redeem-wrap">
                    <div className="title PermanentMarker">
                        Redeem Your Digital Assets
                    </div>
                    {redemptionStatus === 2 ? 
                        <div className="redeem-this-asset-wrap">
                            <div className={`file-wrap ${downloadStatus === 1 ? 'loading' : ''} ${downloadStatus === 3 ? 'downloaded' : ''} ${downloadStatus === 2 ? 'error' : ''} `} onClick={clickToRedeem}>
                            <img className="fileFolderIcon" src="/img/future-file.png" />
                            <div className="fileDetails">
                                <div className="fileName Urbanist">
                                    {redeemDetails.name}
                                </div>
                                <div className="spec1">
                                    1200 images
                                </div>
                                <div className="spec2">
                                    2.3 GB
                                </div>
                                <div className="spec3">
                                    Purchased on {new Date().toLocaleString('en-US', {
                                        month: '2-digit',
                                        day: '2-digit',
                                        year: '2-digit',
                                        hour: 'numeric',
                                        minute: 'numeric',
                                        hour12: false
                                    })}
                                </div>
                            </div>
                            </div>
                            <div className={`text-box Urbanist`}>
                                <TextBox downloadStatus={downloadStatus} 
                                    downloadErrorMessage={downloadErrorMessage}
                                />                               
                            </div>
                            {downloadStatus === 1 ? 
                            
                                <ProgressBar 
                                    progress={downloadProgress} 
                                    downloadStatus={downloadStatus}
                                />

                                :

                                ''

                            }

                        </div>

                        :

                        ''
                    }
                    <form onSubmit={handleSubmit} className={`${redemptionStatus === 2 ? 'hide-form' : ''}`}>
                        <div className="error-message">
                            {redemptionStatus === 1 && redemptionError ?
                                    <div className="error-animation Urbanist">
                                        {redemptionErrorMessage}
                                    </div>
                                    

                                    :

                                    ''
                            }
                        </div>
                    <div className={`order-id-inputs ${redeemLoading ? 'loading' : ''} ${redemptionError ? 'error' : ''}`}>
                        <label htmlFor="orderID">Order ID:</label>
                        <input
                        type="text"
                        id="orderID"
                        name="orderID"
                        value={formData.orderID}
                        onChange={handleInputChange}
                        placeholder="Enter your order id"
                        autoComplete='off'
                        required
                        />
                    </div>
                    <div className={`order-secret-inputs ${redeemLoading ? 'loading' : ''} ${redemptionError ? 'error' : ''}`}>
                        <label htmlFor="orderSecretKey">Secret Key:</label>
                        <input
                        type="password"
                        id="orderSecretKey"
                        name="orderSecretKey"
                        value={formData.orderSecretKey}
                        onChange={handleInputChange}
                        placeholder="Enter your order secret key"
                        autoComplete='off'
                        required
                        />
                    </div>
                    {redemptionStatus === 0 && !redeemLoading ? 
                                        <button type="submit" disabled={!isFormComplete}>
                                        Redeem
                                    </button>
                                    
                                    :

                                    ''
                    }
                    {redemptionStatus === 0 && redeemLoading ?
                            <div class="loader-5 center"><span></span></div>

                            :

                            ''
                    }



                    {/* {!isFormComplete && (
                        <p style={{ color: 'red' }}>Please complete both fields to redeem.</p>
                    )} */}
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Home;


const ProgressBar = ({ progress, downloadStatus }) => {
  return (
    <div className={`progress-bar ${downloadStatus === 3 ? 'succeed' : ''} ${downloadStatus === 2 ? 'fail' : ''} `}>
      <div className="progress" style={{ width: `${progress}%` }} />
    </div>
  );
};


const TextBox = ({downloadStatus,downloadErrorMessage}) => {
    //downloadStatus 
        // 0 = Not Started
        // 1 = Download Triggered
        // 2 = Download Failed
        //3 = Downlaod Success

    let content;

    if(downloadStatus === 0){
        content = <div className={`text1`}>CLICK TO DOWNLOAD</div>
    }
    if(downloadStatus === 1){
        content = <React.Fragment>
                        <div className={`text1 exit`}>CLICK TO DOWNLOAD</div>
                        <div className={`text2`}>DOWNLOAD STARTED</div>
                    </React.Fragment> 
            
    }
    if(downloadStatus === 2){
        content = <React.Fragment>
                    <div className={`text2 exit`}>DOWNLOAD STARTED</div>
                    <div class="notification error">
                        <span class="icon">⚠️</span>
                        <span class="message">{downloadErrorMessage}</span>
                    </div>
                </React.Fragment>
    }
    if(downloadStatus === 3){
        content = <React.Fragment>
            <div className={`text2 exit`}>DOWNLOAD STARTED</div>
                <div class="notification success text3">
                    <span class="icon">✅</span>
                    <span class="message">DOWNLOAD COMPLETE</span>
                </div>
                </React.Fragment>
    }
    return content;
}